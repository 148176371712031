<template>

  <!-- Лента пользователя -->
  <v-container id="Documents" class="h-100">

    <div class="back-button text-right">
      <v-btn icon @click="goBack">
        <v-icon color="grey darken-2">mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <div class="pb-3 mb-3">
      <div class="top-text my-3">
        <h1 class="title is-2 black--text">Документы</h1>
      </div>
    </div>

    <div class="remaining-space">
      <!-- Ссылка на Политику использования cookie -->
      <a href="https://документы.чтовезем.рф/documents/CookiePolicy" class="link-wrapper">
        <p class="title is-4 link-content">
          <span class="link-text">Политика использования cookie</span>
          <v-btn class="link-icon" icon>
            <v-icon color="grey darken-2">mdi-chevron-right</v-icon>
          </v-btn>
        </p>
      </a>

      <!-- Ссылка на Политику обработки данных -->
      <a href="https://документы.чтовезем.рф/documents/PrivacyDataPolicy" class="link-wrapper">
        <p class="title is-4 link-content">
          <span class="link-text">Положение о конфедициальности данных</span>
          <v-btn class="link-icon" icon>
            <v-icon color="grey darken-2">mdi-chevron-right</v-icon>
          </v-btn>
        </p>
      </a>

      <!-- Ссылка на График стоимости услуг -->
      <a href="https://документы.чтовезем.рф/documents/MoreOrder" class="link-wrapper">
        <p class="title is-4 link-content">
          <span class="link-text">Дополнительный договор для Исполнителей, зарегистрированных на Платформе</span>
          <v-btn class="link-icon" icon>
            <v-icon color="grey darken-2">mdi-chevron-right</v-icon>
          </v-btn>
        </p>
      </a>

      <!-- Ссылка на Договор оферты -->
      <a href="https://документы.чтовезем.рф/documents/ServiceOfferAgreement" class="link-wrapper">
        <p class="title is-4 link-content">
          <span class="link-text">Договор публичной оферты об оказании услуг</span>
          <v-btn class="link-icon" icon>
            <v-icon color="grey darken-2">mdi-chevron-right</v-icon>
          </v-btn>
        </p>
      </a>

      <!-- Ссылка на Условия использования -->
      <a href="https://документы.чтовезем.рф/documents/TermsOfUse" class="link-wrapper">
        <p class="title is-4 link-content">
          <span class="link-text">Условия использования</span>
          <v-btn class="link-icon" icon>
            <v-icon color="grey darken-2">mdi-chevron-right</v-icon>
          </v-btn>
        </p>
      </a>

    </div>

    <div class="pa-3 pb-0" style="text-align: center;">
      <p class="buttons mb-0 flex justify-center align-center made-in-russia">
        <img
          src="/assets/made_in_russia.png"
          alt="Сделано в России"
          style="width: 50%; max-width: 160px;"
        >
      </p>
    </div>

  </v-container>

</template>

<script>
export default {
  name: 'Documents',
  data() {
    return {
      version: this.$version,
      buildType: process.env.VUE_APP_BUILD_TYPE,
    };
  },
  created() {
  },
  computed: {
    getBuildTypeFormat() {
      let subStr = this.buildType.substring(0, 1);
      if (subStr) {
        if (subStr === 'p') {
          return '';
        } else {
          return subStr;
        }
      } else {
        // We have a big problems
        return 'h';
      }
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  },
};
</script>

<style scoped>
.block-content img {
  max-width: 100%;
  height: auto;
}

.title.is-2 {
  font-size: 1.8rem !important;
}

#Documents.container {
  display: flex;
  flex-direction: column;
}

.remaining-space {
  flex-grow: 1;
}

.made-in-russia {

}

.title.is-4 {
  margin-bottom: 0px !important;
  line-height: normal !important;
}

a {
  color: #363636 !important;
  margin-bottom: 10px;
}


.link-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none; /* Убираем стандартное подчеркивание */
}

.link-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.link-text {
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  flex: 1;
  margin-right: 10px;
}

.link-icon {
  flex-shrink: 0;
}

</style>
